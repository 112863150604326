import painting0 from "./gallery/Painting-0.jpg";
import painting1 from "./gallery/Painting-1.jpg";
import painting2 from "./gallery/Painting-2.jpg";
import painting3 from "./gallery/Painting-3.jpg";
import painting4 from "./gallery/Painting-4.jpg";
import painting5 from "./gallery/Painting-5.jpg";
import painting6 from "./gallery/Painting-6.jpg";
import painting7 from "./gallery/Painting-7.jpg";
import painting8 from "./gallery/Painting-8.jpg";
import painting9 from "./gallery/Painting-9.jpg";
import painting10 from "./gallery/Painting-10.jpg";
import painting11 from "./gallery/Painting-11.jpg";
import painting12 from "./gallery/Painting-12.jpg";
import painting13 from "./gallery/Painting-13.jpg";
import painting14 from "./gallery/Painting-14.jpg";
import painting15 from "./gallery/Painting-15.jpg";
import painting16 from "./gallery/Painting-16.jpg";
import painting17 from "./gallery/Painting-17.jpg";
import painting18 from "./gallery/Painting-18.jpg";
import painting19 from "./gallery/Painting-19.jpg";
import painting20 from "./gallery/Painting-20.jpg";
import painting21 from "./gallery/Painting-21.jpg";
import painting22 from "./gallery/Painting-22.jpg";
import painting23 from "./gallery/Painting-23.jpg";
import painting24 from "./gallery/Painting-24.jpg";
import painting25 from "./gallery/Painting-25.jpg";
import painting26 from "./gallery/Painting-26.jpg";
import painting27 from "./gallery/Painting-27.jpg";
import painting28 from "./gallery/Painting-28.jpg";
import painting29 from "./gallery/Painting-29.jpg";
import painting30 from "./gallery/Painting-30.jpg";
import painting31 from "./gallery/Painting-31.jpg";
import painting32 from "./gallery/Painting-32.jpg";
import painting33 from "./gallery/Painting-33.jpg";
import painting34 from "./gallery/Painting-34.jpg";
import painting35 from "./gallery/Painting-35.jpg";
import painting36 from "./gallery/Painting-36.jpg";
import painting37 from "./gallery/Painting-37.jpg";
import painting38 from "./gallery/Painting-38.jpg";
import painting39 from "./gallery/Painting-39.jpg";
import painting40 from "./gallery/Painting-40.jpg";
import painting41 from "./gallery/Painting-41.jpg";
import painting42 from "./gallery/Painting-42.jpg";
import painting43 from "./gallery/Painting-43.jpg";
import painting44 from "./gallery/Painting-44.jpg";
import painting45 from "./gallery/Painting-45.jpg";
import painting46 from "./gallery/Painting-46.jpg";
import painting47 from "./gallery/Painting-47.jpg";
import painting48 from "./gallery/Painting-48.jpg";
import painting49 from "./gallery/Painting-49.jpg";
import painting50 from "./gallery/Painting-50.jpg";
import painting51 from "./gallery/Painting-51.jpg";
import painting52 from "./gallery/Painting-52.jpg";
import painting53 from "./gallery/Painting-53.jpg";
import painting54 from "./gallery/Painting-54.jpg";
import painting55 from "./gallery/Painting-55.jpg";
import painting56 from "./gallery/Painting-56.jpg";
import painting57 from "./gallery/Painting-57.jpg";
import painting58 from "./gallery/Painting-58.jpg";
import painting59 from "./gallery/Painting-59.jpg";
import painting60 from "./gallery/Painting-60.jpg";
import painting61 from "./gallery/Painting-61.jpg";
import painting62 from "./gallery/Painting-62.jpg";

// duplicates 11, 17, 19, 20, 24

const artwork = [
  {
    index: 0,
    img: painting0,
    name: "The Answer is Within",
    size: '22.5" x 30"',
  },
  { index: 1, img: painting1, name: "Redhead Self Portrait", size: "SOLD" },
  { index: 2, img: painting2, name: "Bass Guitar", size: '22.5" x 30"' },
  { index: 3, img: painting3, name: "Birds of Paradise", size: '30" x 22.5"' },
  {
    index: 4,
    img: painting4,
    name: "B&W Dancing Flowers",
    size: '22.5" x 30"',
  },
  {
    index: 5,
    img: painting5,
    name: "Breakfast Bagels in Lines",
    size: '22.5" x 30"',
  },
  { index: 6, img: painting6, name: "Dancing Lines", size: '22.5" x 30"' },
  { index: 7, img: painting7, name: "Dancing Lines II", size: '30" x 22.5"' },
  { index: 8, img: painting8, name: "Dancing Pears", size: "SOLD" },
  { index: 9, img: painting9, name: "Dancing Woman I", size: '22.5" x 30"' },
  { index: 10, img: painting10, name: "Dancing Woman II", size: '22.5" x 30"' },
  { index: 11, img: painting11, name: "Disco Dance", size: '22.5" x 30"' },
  { index: 12, img: painting12, name: "Flow", size: "SOLD" },
  {
    index: 13,
    img: painting13,
    name: "Flowers Doing the Swing",
    size: '22.5" x 30"',
  },
  { index: 14, img: painting14, name: "Frolicing Lines", size: '22.5" x 30"' },
  { index: 15, img: painting15, name: "Grass Band", size: '22.5" x 30"' },
  {
    index: 16,
    img: painting16,
    name: "Dance Until Morning",
    size: '17.5" x 21.5"',
  },
  { index: 17, img: painting48, name: "All In Harmony", size: '18" x 24"' },
  {
    index: 18,
    img: painting18,
    name: "Turn The Beat Around",
    size: '30" x 22.5"',
  },
  { index: 19, img: painting19, name: "none", size: '22.5" x 30"' },
  { index: 20, img: painting20, name: "In Motion", size: '22.5" x 30"' },
  { index: 21, img: painting21, name: "Instrumental", size: '12.5" x 10.25"' },
  { index: 22, img: painting22, name: "Life's Journey", size: '22.5" x 30"' },
  { index: 23, img: painting23, name: "Male Energy", size: '30" x 22.5"' },
  {
    index: 24,
    img: painting24,
    name: "Multi-Media Collage I",
    size: '22.5" x 30"',
  },
  {
    index: 25,
    img: painting25,
    name: "Multi-Media Year Round",
    size: '22.5" x 30"',
  },
  {
    index: 26,
    img: painting26,
    name: "Multi-Media Collage II",
    size: '22.5" x 30"',
  },
  { index: 27, img: painting27, name: "Musical Lines", size: '30" x 22.5"' },
  { index: 28, img: painting28, name: "Nude Dancer", size: '17.5" x 21.5"' },
  {
    index: 29,
    img: painting29,
    name: "Redhead During Covid",
    size: '24" x 24"',
  },
  { index: 30, img: painting30, name: "Redhead I", size: "SOLD" },
  { index: 31, img: painting31, name: "Redhead in the Wind", size: "SOLD" },
  { index: 32, img: painting32, name: "Rythmic Flowers", size: '22.5" x 30"' },
  { index: 33, img: painting33, name: "Search Within I", size: '22.5" x 30"' },
  { index: 34, img: painting34, name: "The Piano", size: '36" x 36"' },
  { index: 35, img: painting35, name: "The Reggae Woman", size: '22.5" x 30"' },
  { index: 36, img: painting36, name: "Wallflower", size: '30" x 22.5"' },
  { index: 37, img: painting37, name: "Windy Day", size: '36" x 36"' },
  {
    index: 38,
    img: painting38,
    name: "Woman Model of Lines",
    size: '22.5" x 30"',
  },
  {
    index: 39,
    img: painting39,
    name: "Woman in the Mirror",
    size: '22.5" x 30"',
  },
  {
    index: 40,
    img: painting40,
    name: "Woman Masked for Work",
    size: '22.5" x 30"',
  },
  {
    index: 41,
    img: painting41,
    name: "Tango",
    size: '22.5" x 30"',
  },
  {
    index: 42,
    img: painting42,
    name: "Stringing Along",
    size: '22.5" x 30"',
  },
  {
    index: 43,
    img: painting43,
    name: "Blowing in the Wind",
    size: '22.5" x 30"',
  },
  {
    index: 44,
    img: painting44,
    name: "Rhythm in Blue",
    size: '22.5" x 30"',
  },
  {
    index: 45,
    img: painting45,
    name: "Moon River",
    size: '22.5" x 30"',
  },
  {
    index: 46,
    img: painting46,
    name: "Moving Along I",
    size: '24" x 24"',
  },
  {
    index: 47,
    img: painting47,
    name: "Moving Along II",
    size: '24" x 24"',
  },
  { index: 48, img: painting48, name: "", size: '22.5" x 30"' },
  { index: 49, img: painting49, name: "Rhythm and Blues II", size: '22.5" x 30"' },
  { index: 50, img: painting50, name: "Circle Dance", size: '22.5" x 30"' },
  { index: 51, img: painting51, name: "I AM Woman", size: '22.5" x 30"' },
  { index: 52, img: painting52, name: "Reggae II", size: '22.5" x 30"' },
  { index: 53, img: painting53, name: "Nature's Blessings", size: '30" x 22.5"' },
  {
    index: 54,
    img: painting54,
    name: "En Pointe",
    size: '22.5" x 30"',
  },
  {
    index: 55,
    img: painting55,
    name: "Falling Petals",
    size: '22.5" x 30"',
  },
  {
    index: 56,
    img: painting56,
    name: "I've Got Rhythm",
    size: '22.5" x 30"',
  },
  {
    index: 57,
    img: painting57,
    name: "Belly Dancer",
    size: '22.5" x 30"',
  },
  {
    index: 58,
    img: painting58,
    name: "Whirling Lines",
    size: '22.5" x 30"',
  },
  {
    index: 59,
    img: painting59,
    name: "Disappearing Lines",
    size: '22.5" x 30"',
  },
  {
    index: 60,
    img: painting60,
    name: "A Lined Face",
    size: '22.5" x 30"',
  },
  {
    index: 61,
    img: painting61,
    name: "Ballet Dancer",
    size: '22.5" x 30"',
  },
  {
    index: 62,
    img: painting62,
    name: "Go Your Own Way",
    size: '22.5" x 30"'
  }
];

// let artwork = [];
// (() => {
//   let i = 0;
//   paintingArr.forEach((painting) => {
//     let paintingObj = { index: i, img: painting };
//     i++;
//     artwork.push(paintingObj);
//   });
// })();

const group1 = [
  artwork[14],
  artwork[6],
  artwork[41],
  artwork[42],
  artwork[8],
  artwork[44],
  artwork[12],
  artwork[56],
];
const group2 = [
  artwork[43],
  artwork[20],
  artwork[0],
  artwork[33],
  artwork[49],
  artwork[22],
  artwork[21],
  artwork[50],
];
const group3 = [
  artwork[30],
  artwork[51],
  artwork[31],
  artwork[1],
  artwork[9],
  artwork[10],
  artwork[35],
  artwork[52],
];
const group4 = [
  artwork[28],
  artwork[24],
  artwork[25],
  artwork[62],
  artwork[54],
  artwork[5]
];

const group5 = [
  artwork[7],
  artwork[18],
  artwork[23],
  artwork[27]
];

const group6 = [artwork[32], artwork[4], artwork[13], artwork[55], artwork[53], artwork[37], artwork[3]];

const group7 = [artwork[58], artwork[60], artwork[59], artwork[38], artwork[39],
artwork[57],
artwork[61]]

const homeGroup = [artwork[29], artwork[20], artwork[1], artwork[0]];

export { artwork, group1, group2, group3, group4, group5, group6, group7, homeGroup };
