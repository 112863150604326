import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Lightbox from "../components/Lightbox";
import { group1, group2, group3, group4, group5, group6, group7 } from "../images";
import * as Scroll from "react-scroll";

// Collage Gallery Items
const GalleryCol = (props) => {
  // console.log(props.group);
  return (
    <Col
      xs={6}
      sm={6}
      md={4}
      lg={3}
      className="d-flex flex-column"
      style={{ maxWidth: "350px" }}
      key={props.group}
    >
      {props.group.map((painting) => (
        <img
          src={painting.img}
          alt=""
          className="img-fluid m-1"
          key={painting.index}
          data-index={painting.index}
        />
      ))}
    </Col>

    // return
    /* Gallery Collage View
        <Row className="d-flex flex-row flex-wrap justify-content-center">
        <GalleryCol group={group1} />
        <GalleryCol group={group2} />
        <GalleryCol group={group3} />
        <GalleryCol group={group4} />
        </Row>

      */
  );
};

// Linear Gallery Items

const LinearGallery = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [currentPainting, setCurrent] = React.useState(null);

  // console.log(props.group);

  const handleClick = (painting) => {
    // console.log(painting);
    setModalShow(true);
    setCurrent(painting);
  };

  return (
    <>
      <Container className="w-100 bg-light mt-2 mb-3">
        <h1 className="p-1 category">{props.category}</h1>
      </Container>
      <Row className="justify-content-md-between gx-5">
        {props.group.map((painting) => (
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            className="center flex-column"
            key={painting.index}
          >
            <img
              src={painting.img}
              alt={painting.name}
              className="gallery-image"
              data-index={painting.index}
              role="button"
              onClick={() => handleClick(painting.index)}
            />
            <div className="center mb-1">{painting.name}</div>
            <div className="center mb-4">{painting.size}</div>
          </Col>
        ))}
      </Row>
      {modalShow && (
        <Lightbox
          show={modalShow}
          imgIndex={currentPainting}
          onHide={() => setModalShow(false)}
        />
      )}
    </>
  );
};

const Gallery = () => {
  const [currentPage, setPage] = React.useState(1);

  const handlePageChange = (pageNumber) => {
    const scroll = Scroll.animateScroll;
    scroll.scrollToTop({ delay: 0, duration: 0 });
    setPage(pageNumber);
  };

  const activeClass = (pageNumber) => {
    if (currentPage === pageNumber) {
      return "active-page page-numbers";
    } else {
      return "page-numbers";
    }
  };

  const pageView = () => {
    switch (currentPage) {
      case 1:
        return (
          <>
            <LinearGallery group={group1} category="Dancing Lines" />
          </>
        );
      case 2:
        return (
          <>
            <LinearGallery group={group2} category="Lines In Motion" />
          </>
        );
      case 3:
        return (
          <>
            <LinearGallery group={group3} category="Women" />
          </>
        );
      case 4:
        return (
          <>
            <LinearGallery group={group4} category="Break Dancing" />
          </>
        );
      case 5:
        return (
          <>
            <LinearGallery group={group5} category="All About Lines" />
          </>
        );
      case 6:
        return (
          <>
            <LinearGallery group={group6} category="The Harmony of Nature" />
          </>
        );
      case 7:
        return (
          <>
            <LinearGallery group={group7} category="Lines Inside Out" />
          </>
        );
      default:
        break;
    }
  };
  return (
    <section>
      <h1 className="heading">Gallery</h1>
      <p className="text-center fs-5">Click on a painting to enlarge it</p>
      <Container className="container-fluid">{pageView()}</Container>
      <Container className="center mt-3">
        <div className={activeClass(1)} onClick={() => handlePageChange(1)}>
          1
        </div>
        <div className={activeClass(2)} onClick={() => handlePageChange(2)}>
          2
        </div>
        <div className={activeClass(3)} onClick={() => handlePageChange(3)}>
          3
        </div>
        <div className={activeClass(4)} onClick={() => handlePageChange(4)}>
          4
        </div>
        <div className={activeClass(5)} onClick={() => handlePageChange(5)}>
          5
        </div>
        <div className={activeClass(6)} onClick={() => handlePageChange(6)}>
          6
        </div>
        <div className={activeClass(7)} onClick={() => handlePageChange(7)}>
          7
        </div>
      </Container>
    </section>
  );
};

export default Gallery;
