import React from "react";
import AboutPage from "./About";
import HomePage from "./Home";
import GalleryPage from "./Gallery";
import ContactPage from "./Contact";
import FooterPage from "./Footer";

export const Home = () => {
  return <HomePage />;
};

export const About = () => {
  return <AboutPage />;
};

export const Gallery = () => {
  return <GalleryPage />;
};

export const Contact = () => {
  return <ContactPage />;
};

export const Footer = () => {
  return <FooterPage />;
};
