import aboutPhoto from "../images/about.jpg";
import { Container, Row, Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const AboutPage = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <Container className="center mt-5">
              <img
                src={aboutPhoto}
                alt="Mary Ann in the studio"
                className="contact-image"
              />
            </Container>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <Container fluid="true" className="vcenter">
              <Container>
                <h1 className="heading">About Mary Ann</h1>
                <p className="fs-5">
                  {
                    "After studying art with Tammra Sigler, a famous Maryland and Florida artist and instructor, Mary Ann has decided to share her works with others who may enjoy the open and frolicking designs. Nothing held back…nothing contrived…Musically inspired paintings coupled with Mary Ann’s psychology and dance background enhances her joy of painting."
                  }
                </p>
                <p className="fs-5">
                  {
                    "Mary Ann has enjoyed several careers in the fields of Psychology, Education, and the Business arenas. She found sculpture several years ago and practiced working with clay for 5 years.  She then took a class in drawing but eventually found Painting a joy and her way of meditating throughout the day. Mary Ann aims to shape her environment by building relationships both personal and professional. She balances her love for people through training professional managers and spending time with artists and friends. "
                  }
                </p>
                <p className="fs-5">
                  {
                    "Enjoy the journey….Take a peek…and let her know if she can liven up your home or office."
                  }
                </p>
                <p className="fs-5">
                  {"DANCING LINES WILL FILL YOUR HEART WITH JOY!"}
                </p>
                <Container className="center mt-5">
                  <Button
                    variant="outline-primary"
                    size="lg"
                    onClick={() => document.querySelector(".gallery").click()}
                  >
                    Visit the Gallery
                  </Button>
                </Container>
              </Container>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutPage;
