import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import CarouselPage from "../components/Carousel";
import studioPic from "../images/dancingLinesStudio.jpg";
import homeStudio from "../images/homeStudio.jpg";
import redHeadStudio from "../images/redHeadStudio.jpg";
import { homeGroup, artwork } from "../images";

const HomeGallery = () => {
  const [currentPainting, setCurrent] = React.useState(29);
  const mainImage = artwork[currentPainting].img;
  const removeBorder = () => {
    const painting1 = document.querySelector(`[data-index="29"]`);
    const painting2 = document.querySelector(`[data-index="20"]`);
    const painting3 = document.querySelector(`[data-index="1"]`);
    const painting4 = document.querySelector(`[data-index="0"]`);
    const homeGalleryImages = [painting1, painting2, painting3, painting4];
    homeGalleryImages.forEach((e) => {
      e.classList.remove("selected-home");
    });
  };
  const handleClick = (painting) => {
    removeBorder();
    setCurrent(painting);
    const selectedPainting = document.querySelector(
      `[data-index="${painting}"]`
    );
    selectedPainting.classList.add("selected-home");
  };
  return (
    <>
      <Container className="center home-splash">
        <Container className="center mt-4">
          <img src={mainImage} alt="" className="img-fluid home-image" />
        </Container>
        <Row>
          <Col>
            <div className="home-gallery-wrapper">
              <div className="ms-3">
                <p className="text-center m-0">Click to enlarge</p>
              </div>
              {homeGroup.map((painting) => (
                <div className="" key={painting.name}>
                  <img
                    src={painting.img}
                    alt=""
                    className={"home-gallery-sm p-1"}
                    key={painting.index}
                    data-index={painting.index}
                    onClick={() => handleClick(painting.index)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const HomePage = () => {
  return (
    <section>
      <h1 className="text-center tag-line font">Dancing Lines</h1>
      <h2 className="subheading tag-line">
        Want To Fill Your Home or Office With Lively Paintings?
      </h2>
      {<HomeGallery />}
      <h3 className="signature">
        Art by Mary Ann Needham - Acrylic Painter & Artist Based In Naples,
        Florida
      </h3>
      <Container className="d-flex">
        <Row>
          <Col xs={12} s={12} m={8} lg={8} className="center">
            <img src={homeStudio} alt="" className="img-fluid about-image" />
          </Col>
          <Col className="text-side mt-4">
            <h3>
              Mary Ann uses mostly acrylic paints on paper while dancing and
              singing in her home studio. I think they speak for themselves of
              how inspired and deeply committed she is to the FLOW of ART. Enjoy
              the journey.
            </h3>
            <Container className="center mt-4">
              <Button
                variant="outline-primary"
                size="lg"
                onClick={() => document.querySelector(".about").click()}
              >
                About Me
              </Button>
            </Container>
          </Col>
        </Row>
      </Container>
      <Row className="m-1">
        <Col xs={12} lg={12} className="center">
          {/* <img src={studioPic} alt="" className="img-fluid home-image" /> */}
        </Col>
        {/* <Col xs={4} className="center">
          <img src={redHeadStudio} alt="" className="img-fluid home-image" />
        </Col> */}
      </Row>
      <Container className="center mt-4 mb-4">
        <h3>
          Take a peek and let her know if she can liven up your home or office.
        </h3>
      </Container>
      <Row className="home-row m-4">
        <Col xs={6} sm={6} md={6} lg={6} xl={4} className="center order1">
          <img
            src={redHeadStudio}
            alt="redhead painting in the studio"
            className="img-fluid home-row-image"
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={4} className="center order2">
          <img
            // src={artwork[37].img}
            src={studioPic}
            alt="multiple paintings in the studio"
            className="img-fluid home-row-image studio-pic"
          />
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={4} className="center order3">
          <img
            src={artwork[43].img}
            alt="orange and blue abstract painting"
            className="img-fluid home-row-image"
          />
        </Col>
      </Row>
      <Container className="center mt-5">
        <Button
          variant="outline-primary"
          size="lg"
          onClick={() => document.querySelector(".gallery").click()}
        >
          Visit the Gallery
        </Button>
      </Container>
    </section>
  );
};

export default HomePage;
