import React, { useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import { artwork } from "../images";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import Reaptcha from "reaptcha";

const ContactPage = () => {
  const form = useRef();
  const [verified, setVerified] = React.useState(false);
  const onVerify = (e) => {
    setVerified(true);
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hajnwng",
        "template_k06dlak",
        form.current,
        "user_WJnIsN87neAVbXRlst6No"
      )
      .then(
        function (response) {
          Swal.fire({
            title: "Message Sent!",
            text: "Thank you for your message! I will respond shortly.",
            icon: "success",
            confirmButtonText: "Ok",
          });
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          Swal.fire({
            title: "Whoops!",
            text: "Something went wrong.",
            icon: "error",
            confirmButtonText: "Ok",
          });
          console.log("FAILED...", error);
        }
      );
    e.target.reset();
  };
  return (
    <section>
      <h1 className="heading">Contact</h1>
      <Container>
        <Row>
          <Col xs={12} lg={6} className="contact-content">
            <Container>
              <h1 className="heading">Let's Get In Touch</h1>
            </Container>
            <Container className="mb-5">
              <p>
                “I feel that there is nothing more truly artistic than to love
                people.”
              </p>
              <p>- Vincent Van Gogh</p>
              <br />
              <p>
                For purchasing information and comments please use the form
                below.
              </p>
              <p>
                Please reach out and send me a message. I look forward to our
                new connection.
              </p>
              <p>Mary Ann</p>
            </Container>
            <Form ref={form} onSubmit={sendEmail}>
              <Form.Group className="mb-3" controlId="formBasicName">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Name"
                  className="mb-3 w-75 mx-auto"
                >
                  <Form.Control
                    type="text"
                    placeholder="First Last"
                    name="name"
                    autoComplete="on"
                    required
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email address"
                  className="mb-3 w-75 mx-auto"
                >
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    name="email"
                    autoComplete="on"
                    required
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formComment"></Form.Group>
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Comments"
                className="w-75 mx-auto"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  name="message"
                  style={{ height: "100px" }}
                  required
                />
              </FloatingLabel>
              <div className="mt-4 center">
                <Reaptcha
                  sitekey="6LePLPMcAAAAAD4Cih8cigd-Jq7mlk3zIA1rQ4AT"
                  onVerify={onVerify}
                />
              </div>
              <Container className="center mt-4">
                <Button
                  variant="outline-primary"
                  type="submit"
                  size="lg"
                  className="w-75 mb-4"
                  disabled={!verified}
                >
                  Send
                </Button>
              </Container>
            </Form>
          </Col>
          <Col md={12} lg={6}>
            <Container className="center">
              <div>
                <img
                  src={artwork[30].img}
                  alt="red head abstract"
                  className="contact-image"
                />
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactPage;
