import { Container, Row, Col, Button } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="bg-light">
      <Container>
        <Row className="vcenter">
          <Col className="footer-content">Dancing Lines © 2021</Col>
          <Col className="footer-content justify-content-end">
            <span>
              {"Website built by "}
              <a href="https://techxguy.com" rel="noreferrer" target="_blank">
                {"Tech X"}
              </a>
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
