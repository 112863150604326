import { Navbar, Nav, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
// import ShareBtn from "../components/ShareBtn";
// import logo from "../images/logoSample.png";

export const NavbarBar = () => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="light"
      bg="light"
      className=""
      // style={{ maxHeight: "50px" }}
      fixed="top"
    >
      <Container style={{ maxWidth: "90vw !important" }}>
        <Navbar.Brand
          onClick={() => document.querySelector(".home").click()}
          role="button"
        >
          {/* <img
            alt="Dancing lines logo"
            src={logo}
            width="60"
            height="30"
            className="d-inline-block"
          /> */}
          <span className="fs-4">{" Dancing Lines"}</span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            <LinkContainer exact={true} to="/">
              <Nav.Link className="fs-5 home">Home</Nav.Link>
            </LinkContainer>
            <LinkContainer exact={true} to="/about">
              <Nav.Link className="fs-5 about">About</Nav.Link>
            </LinkContainer>
            <LinkContainer exact={true} to="/gallery">
              <Nav.Link className="fs-5 gallery">Gallery</Nav.Link>
            </LinkContainer>
            {/*
            <Nav.Link className="fs-5 store">Store</Nav.Link> */}

            <LinkContainer exact={true} to="/contact">
              <Nav.Link className="fs-5 contact">Contact</Nav.Link>
            </LinkContainer>
            {/* <ShareBtn /> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

// export default NavbarBar;
