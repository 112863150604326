import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { artwork } from "../images";
import CarouselPage from "./Carousel";

const Lightbox = (props) => {
  let index = props.imgIndex;
  let currentPaintingImg = artwork[index].img;
  let currentPainting = artwork[index];

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="lightbox"
      centered
    >
      <Modal.Header closeButton style={{ maxHeight: "2rem" }}>
        {currentPainting.name != "none"
          ? `${currentPainting.name} - ${currentPainting.size}`
          : null}
      </Modal.Header>
      <Modal.Body className="lightbox-body">
        {/* <h4 className="text-center">Title</h4> */}
        <Container className="center">
          <img src={currentPaintingImg} alt="" className="lightbox-slide" />
        </Container>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default Lightbox;
